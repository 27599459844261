<template>
    <LayoutNext>
        <template #page-title>
            Daily Sync
        </template>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="d-md-flex d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-start mb-3 mb-md-0 align-items-center">
                    <!-- <router-link :to="{ name: 'AddCreator' }">
                        <MDBBtn color="primary" class="fw-bold px-3 py-2" size="sm">
                            <MDBIcon iconStyle="fas" icon="user-plus" class="me-1"></MDBIcon> Add Creator
                        </MDBBtn>
                    </router-link> -->
                    <MDBBtn color="primary" class="fw-bold px-3 py-2" size="sm" @click="addCreatorModal = true">
                        <MDBIcon iconStyle="fas" icon="user-plus" class="me-1"></MDBIcon> Add Creator
                    </MDBBtn>
                </div>
                <div class="d-flex justify-content-end">
                    <Search :searchType="'notifManagement'" @searchResult="notifManagementList = $event"
                        placeholder="Enter to Search" />
                </div>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div class="overflow-auto mt-3" id="table-container">
                    <MDBTable hover class="align-middle mb-0">
                        <thead class="table-light">
                            <tr>
                                <th v-for="(item, i) in tableHeaders" :key="i" scope="col"
                                    class="text-nowrap sticky-top">
                                    {{ item.header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="isLoading">
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <div class="image-container d-flex justify-content-center align-items-center">
                                            <div class="loader"></div>
                                            <img src="@/assets/cs-loader.png" alt="Loading image">
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="notifManagementList.data.length === 0">
                                    <td colspan="3" class="text-center py-3">No creator found.</td>
                                </tr>
                                <tr v-for="(item, i) in notifManagementList.data" :key="i">
                                    <td>{{ item.creatorName }}</td>
                                    <td>{{ item.recentVideoTitle }}</td>
                                    <td class="text-nowrap">
                                        <!-- <router-link :to="{ name: 'UpdateCreator', params: { id: item.id } }">
                                            <MDBBtn color="primary" size="sm">
                                                <MDBIcon iconStyle="fas" icon="pen" />
                                            </MDBBtn>
                                        </router-link> -->
                                        <div class="d-flex">
                                            <MDBBtn class="me-2" rounded outline="primary" size="sm"
                                                @click.stop="clickAction(item, 'update')">
                                                <vue-feather type="edit" :stroke-width="3" size="16" />
                                            </MDBBtn>
                                            <MDBBtn size="sm" rounded outline="primary"
                                                @click.stop="clickAction(item, 'delete')">
                                                <vue-feather type="trash-2" :stroke-width="3" size="16" />
                                            </MDBBtn>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </MDBTable>
                </div>
            </MDBCardBody>
            <div class="overflow-auto">
                <Paginate :pageType="'notifManagementList'" @paginationData="notifManagementList = $event">
                </Paginate>
            </div>
            <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal">
                <MDBModalHeader>
                    <MDBModalTitle class="fw-bold" id="deleteModalLabel"> Delete Creator Notification </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody>Are you sure you want to delete this setting?</MDBModalBody>
                <MDBModalFooter class="gap-2 py-3">
                    <MDBBtn class="fw-bold" size="sm" @click="deleteModal = false">Close</MDBBtn>
                    <MDBBtn class="fw-bold" color="danger" size="sm" @click="deleteNotification(modalItem)">Confirm
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBModal id="addCreatorModal" tabindex="-1" labelledby="addCreatorModal" v-model="addCreatorModal"
                class="ps-0" :static-backdrop="true">
                <MDBModalHeader>
                    <MDBModalTitle id="addCreatorModal" class="fw-bold"> Add Creator for Notification </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody class="d-flex flex-column px-4" style="gap: 15px">
                    <div>
                        <label for="creatorName">Creator Name</label>
                        <MDBInput class="mt-1" type="text" placeholder="Enter Creator Name"
                            v-model="addForm.creatorName" id="creatorName" />
                    </div>
                    <div>
                        <label for="videoPageLink">Videos Page Link</label>
                        <MDBInput class="mt-1" type="text" placeholder="Enter Video Page Link"
                            v-model="addForm.videosPageLink" id="videoPageLink" />
                    </div>
                    <div>
                        <div>
                            <label for="frequencyType">Scan FrequencyType</label>
                            <select v-model="addForm.frequencyType" class="did-floating-select mt-1" id="frequencyType">
                                <option v-for="option in frequencyType" :key="option.value" :value="option.value">{{
                                    option.text
                                }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="addForm.frequencyType == '2'">
                        <label for="week">Day of the Week</label>
                        <select v-model="addForm.frequency" class="did-floating-select mt-1" id="week">
                            <option v-for="option in dayOfTheWeek" :key="option.value" :value="option.value">{{
                                option.text
                            }}
                            </option>
                        </select>
                    </div>
                    <div v-if="addForm.frequencyType == '3'" class="mt-1">
                        <label for="monthly">Day of the Week</label>
                        <select v-model="addForm.frequency" class="did-floating-select mt-1" id="monthly">
                            <option v-for="option in dayOfTheMonth" :key="option.value" :value="option.value">{{
                                option.text
                            }}
                            </option>
                        </select>
                    </div>
                </MDBModalBody>
                <MDBModalFooter class="py-3 d-flex justify-content-end gap-2">
                    <MDBBtn @click="addCreatorModal = false" size="sm" class="fw-bold"> Close </MDBBtn>
                    <MDBBtn class="fw-bold" type="submit" @click="addCreator" color="primary" size="sm"
                        :disabled="isAddBtnDisabled">Add
                        Creator
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBModal id="updateCreatorModal" tabindex="-1" labelledby="updateCreatorModal" v-model="updateCreatorModal"
                class="ps-0" :static-backdrop="true">
                <MDBModalHeader>
                    <MDBModalTitle id="updateCreatorModal" class="fw-bold"> Update Creator for Notification
                    </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody class="d-flex flex-column px-4" style="gap: 15px">
                    <div>
                        <label for="creatorName">Creator Name</label>
                        <MDBInput class="mt-1" type="text" placeholder="Enter Creator Name"
                            v-model="updateForm.creatorName" id="creatorName" />
                    </div>
                    <div>
                        <label for="videoPageLink">Videos Page Link</label>
                        <MDBInput class="mt-1" type="text" placeholder="Enter Video Page Link"
                            v-model="updateForm.videosPageLink" id="videoPageLink" />
                    </div>
                    <div>
                        <div>
                            <label for="frequencyType">Scan FrequencyType</label>
                            <select v-model="updateForm.frequencyType" class="did-floating-select mt-1"
                                id="frequencyType">
                                <option v-for="option in frequencyType" :key="option.value" :value="option.value">{{
                                    option.text
                                }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="updateForm.frequencyType == '2'">
                        <label for="week">Day of the Week</label>
                        <select v-model="updateForm.frequency" class="did-floating-select mt-1" id="week">
                            <option v-for="option in dayOfTheWeek" :key="option.value" :value="option.value">{{
                                option.text
                            }}
                            </option>
                        </select>
                    </div>
                    <div v-if="updateForm.frequencyType == '3'" class="mt-1">
                        <label for="monthly">Day of the Week</label>
                        <select v-model="updateForm.frequency" class="did-floating-select mt-1" id="monthly">
                            <option v-for="option in dayOfTheMonth" :key="option.value" :value="option.value">{{
                                option.text
                            }}
                            </option>
                        </select>
                    </div>
                </MDBModalBody>
                <MDBModalFooter class="py-3 d-flex justify-content-end gap-2">
                    <MDBBtn @click="updateCreatorModal = false" size="sm" class="fw-bold"> Close </MDBBtn>
                    <MDBBtn class="fw-bold" type="submit" @click="updateCreator(modalItem)" color="primary" size="sm"
                        :disabled="isUpdateBtnDisabled">
                        Update
                        Creator</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </MDBCard>
    </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { onBeforeMount, ref, computed, reactive } from "vue";
import axios from "axios";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBTable, MDBIcon, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBInput } from "mdb-vue-ui-kit";
import Search from "@/components/CSDSearch.vue";
import Paginate from "@/components/Pagination/CSDPagination.vue";
import { useToastStore } from "@/store/toastStore";
import { useTitle } from "@vueuse/core";
import { PostCreatorForNotification } from "@/services/Notifications/PostCreatorForNotification";
import { PatchCreatorForNotification } from "@/services/Notifications/PatchCreatorForNotification";
import VueFeather from 'vue-feather';

useTitle("Daily Sync | Creator Shield");

const toastStore = useToastStore();

const tableHeaders = computed(() => [
    { header: "Creator Name" },
    { header: "Recent Video Title" },
    { header: "Actions" }
]);

const notifManagementList = ref({
    data: [],
    pagination: {},
});

const modalItem = ref("");
const deleteModal = ref(false);
const loadingSpinner = ref(false);

const userId = ref();
const clickAction = (item, type) => {
    modalItem.value = item;
    if (type === "delete") {
        deleteModal.value = true;
    }
    if (type === "update") {
        updateCreatorModal.value = true;
        userId.value = modalItem.value.userId;

        updateForm.creatorName = modalItem.value.creatorName;
        updateForm.videosPageLink = modalItem.value.videosPageLink;
        updateForm.frequencyType = modalItem.value.frequencyType;
        updateForm.frequency = modalItem.value.frequency;
    }
};

const deleteNotification = (data) => {
    const toastSuccessDelete = {
        ...toastStore.toastStatus.success,
        message: "Successfully deleted!",
    };
    loadingSpinner.value = true;
    axios
        .patch("api/creatorvideoupdates/" + data.id + "/status")
        .then((response) => {
            if (response.status == 200) {
                getNotificationList();
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastSuccessDelete,
                };
                loadingSpinner.value = false;
                deleteModal.value = false;
            }
        })
        .catch(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
            };
            loadingSpinner.value = false;
        });
};

const isLoading = ref(false);

const getNotificationList = async () => {
    isLoading.value = true;
    await axios
        .get("api/creatorvideoupdates/list")
        .then((response) => {
            isLoading.value = false;
            notifManagementList.value = response.data;
        })
        .catch(() => {
            isLoading.value = false;
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
            };
        });
};

onBeforeMount(() => {
    getNotificationList();
});

const addCreatorModal = ref(false);
const addForm = reactive({
    creatorName: "",
    videosPageLink: "",
    frequencyType: "1",
    frequency: "",
});

const dayOfTheWeek = [
    { text: 'Monday', value: '1' },
    { text: 'Tuesday', value: '2' },
    { text: 'Wednesday', value: '3' },
    { text: 'Thursday', value: '4' },
    { text: 'Friday', value: '5' },
];

const dayOfTheMonth = [
    { text: '1st', value: '1' },
    { text: '15th', value: '15' },
    { text: 'Last', value: '30' },
];

const frequencyType = [
    { text: 'Daily', value: '1' },
    { text: 'Weekly', value: '2' },
    { text: 'Monthly', value: '3' },
];

const isAddBtnDisabled = computed(() => addForm.creatorName === "" || addForm.videosPageLink === "");

const addCreator = async () => {
    const formData = {
        creatorName: addForm.creatorName,
        videosPageLink: addForm.videosPageLink,
        frequencyType: addForm.frequencyType,
        frequency: addForm.frequency,
    };

    loadingSpinner.value = true;
    const toastSuccess = {
        ...toastStore.toastStatus.success,
        message: "Successfully added!",
    };

    await PostCreatorForNotification(formData)
        .then(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
        })
        .catch((response) => {
            if (response.status !== 200 && response.status !== 201) {
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.fail,
                };
            }
        })
        .finally(() => {
            loadingSpinner.value = false;
            addCreatorModal.value = false;
            getNotificationList();
            addForm.creatorName = "";
            addForm.videosPageLink = "";
            addForm.frequencyType = "1";
            addForm.frequency = "";
        });
};

const updateCreatorModal = ref(false);
const updateForm = reactive({
    creatorName: "",
    videosPageLink: "",
    frequencyType: "1",
    frequency: "",
});

const isUpdateBtnDisabled = computed(() => updateForm.creatorName === "" || updateForm.videosPageLink === "");

const updateCreator = async (data) => {
    const formData = {
        id: data.id,
        creatorName: updateForm.creatorName,
        videosPageLink: updateForm.videosPageLink,
        frequencyType: updateForm.frequencyType,
        frequency: updateForm.frequency,
    };

    loadingSpinner.value = true;
    const toastSuccess = {
        ...toastStore.toastStatus.success,
        message: "Successfully added!",
    };

    await PatchCreatorForNotification(formData)
        .then(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
        })
        .catch((response) => {
            if (response.status !== 200 || response.status !== 201) {
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.fail,
                };
            }
        })
        .finally(() => {
            loadingSpinner.value = false;
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
            updateCreatorModal.value = false;
            getNotificationList();
        });
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    /* width: 30px;
    height: 30px;
    display: grid;
    place-content: center; */
    vertical-align: middle;
}

.btn-outline-primary i {
    vertical-align: top;
}

th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 58vh;
}

/* customized select with floating label */
.did-floating-label-content {
    position: relative;
}

.did-floating-label {
    color: #1e4c82;
    font-size: 12.5px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 6px;
    top: 11px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 35px;
    padding: 0 12.5px;
    padding-right: 13px;
    background: #fff;
    color: #6c757d;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: 1.5px solid #0d6efd;

        ~.did-floating-label {
            color: #0d6efd;
            top: -8px;
            font-size: 12.5px;
        }
    }
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.did-floating-select::-ms-expand {
    display: none;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 12.5px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
    top: -8px;
    font-size: 12.5px;
}

.did-floating-select[value=""]:focus~.did-floating-label {
    top: 11px;
    font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {

    .did-floating-input,
    .did-floating-select {
        width: 100%;
    }
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
